import APIService from './APIService';

export default {
  getUsers() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`users/get-users`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getUserById(userId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`users/get-user-by-id?user_id=${userId}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getRoles() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`users/get-roles`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getIndicadoresFieldsCategories(showHidden = false) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`users/get-indicadores-fields-categories?show_hidden=${Number(showHidden)}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  saveUserData(userId, data) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post(`/users/save-user-data?user_id=${userId}`, JSON.stringify(data))
        .then(()  => resolve(true))
        .catch(() => reject(false));
    });
  },
  saveUserProfile(userId, data) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post(`/users/save-user-profile?user_id=${userId}`, JSON.stringify(data))
        .then(()  => resolve(true))
        .catch(() => reject(false));
    });
  },
  getIndicadoresFieldsCategoriesByUser(userId, showHidden = false) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`users/get-indicadores-fields-categories-by-user?user_id=${userId}&show_hidden=${Number(showHidden)}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  alteraStatus(userId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post(`/users/altera-status/${userId}`)
        .then(()  => resolve(true))
        .catch(() => reject(false));
    });
  },
  getAuthorizedCompaniesValidation() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`users/get-companies-allowed-validation`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
}