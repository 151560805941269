<template>
  <v-container fluid class="pt-5">
    <base-material-card
      color="primary"
      icon="mdi-clipboard-outline"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Usuários
        </div>
      </template>
 <v-row>
  <v-col>
    <v-btn 
            id="btn-novo-usuario"
            v-if="tipoAutenticacao == 'database'" 
            color="success"
            dark
            elevation="1"
            class="mt-5"
            style="float: left"
            relative
            text
            medium
            @click="$router.push(`/administracao/usuarios/edit`)"
          >
            <v-icon
              left
              size="30px"
            >
              mdi-plus-circle
            </v-icon>
            Novo Usuário
          </v-btn>

  </v-col>
 </v-row>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 100%"
      />
      
      <v-divider class="mt-10" />

      <v-data-table
        :headers="headers"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loadingTable"
        :hide-default-header="loadingTable"
        :hide-default-footer="loadingTable"
        :sort-by="[]"
        :sort-desc="true"
        no-data-text="Nenhum usuário encontrado"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | parseNumberToIntegerBR }}
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="text-truncate-column"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.name }}
              </div>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="text-truncate-column"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.email }}
              </div>
            </template>
            <span>{{ item.email }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
              <status :status="item.ativo" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="gray"
                fab
                icon
                x-small
                @click="$router.push(`/administracao/usuarios/edit/${item.id}`)"
              >
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-menu offset-y v-if="canChangeStatus(item)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="action-more-usuario"
                    color="white"
                    class="ml-1"
                    height="22px"
                    width="22px"
                    fab
                    x-small
                    elevation="1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    
                    @click="openDialogChangeStatus(item)"
                  >
                    <v-list-item-title>
                      <v-icon
                        small
                        v-if="item.ativo"
                      >
                        mdi-account-cancel
                      </v-icon>
                      <v-icon v-else> mdi-account </v-icon>
                      <span v-if="item.ativo"> Inativar </span>
                      <span v-else> Ativar </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
        </template>
      </v-data-table>
      <dialog-change-status
            :dialog="dialogChangeStatus"
            @update:dialog="dialogChangeStatus = $event"
            @submitTriggered="changeUserStatus(dialogChangeStatusData.id)"
          >
          
            <template slot="title">
              <span v-if="dialogChangeStatusData.ativo">
                Inativar
              </span>
              <span v-else> Ativar </span> usuário #{{
                dialogChangeStatusData.id
              }}
            </template>
            <template slot="body">
              Tem certeza que deseja
              <span v-if="dialogChangeStatusData.ativo">
                inativar
              </span>
              <span v-else> ativar </span>
              
              o usuário <strong>{{ dialogChangeStatusData.name }}</strong
              >?
            </template>
          </dialog-change-status>
    </base-material-card>
  </v-container>
</template>

<script>

import UsersService from '@/services/UsersService';
import AuthService from '@/services/AuthService';
import DialogChangeStatus from '../../../components/general/DialogChangeStatus.vue';
import Status from '@/components/general/Status.vue'
export default {
  name: "UsuariosIndex",
  components: {
    Status: Status,
    DialogChangeStatus: DialogChangeStatus
  },
  data: () => ({
    items: [],
    search: "",
    loadingTable: false,
    usuarioAdmin: false,
    tipoAutenticacao: null,
    dialogChangeStatusData: {
      id: undefined,
      nome: undefined
    },
    dialogChangeStatus:false
  }),
  mounted() {
    this.getUsers();
    this.getUsuarioAdmin();
    this.getTipoAutenticacao();
  },
  computed: {
    usuario() {
      return this.$store.getters.getUser;
    },
    headers(){
      let headers = [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Nome',
        value: 'name'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Perfil',
        value: 'role.role'
      },
    ];
    if(this.tipoAutenticacao === 'database'){
      headers.push({
        text: 'Status',
        value: 'status'
      });
    }
    headers.push( {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      });
      return headers;
    }
  },
  methods: {
    getUsers() {
      this.loadingTable = true;

      UsersService.getUsers()
        .then(res => {
          this.items = res;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => this.loadingTable = false);
    },

    async getUsuarioAdmin() {
      this.usuarioAdmin = await AuthService.userIsAdmin();
    },

    async getTipoAutenticacao() {
      this.tipoAutenticacao = await AuthService.getTipoAutenticacao();
    },

    changeUserStatus(id) {
      UsersService.alteraStatus(id)
        .then(() => {
          this.$toast.success('Status do usuário atualizado com sucesso.', '', {
            position: 'topRight'
          });
          this.getUsers();
        })
        .catch((error) => {
          this.$toast.error('Erro ao atualizar o status do usuário.', '', {
            position: 'topRight'
          });
          console.error(error);
        });

      this.dialogChangeStatus = false;
    },
    canChangeStatus(item) {
      return (
        this.tipoAutenticacao === 'database' &&
        this.usuarioAdmin &&
        this.usuario.id !== item.id &&
        !item.role.admin
      );
    },
    openDialogChangeStatus(usuario) {
      this.dialogChangeStatusData = usuario;
      this.dialogChangeStatus = true;
    }
  }
}

</script>

<style>

</style>