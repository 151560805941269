var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-5",attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-clipboard-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Usuários ")])]},proxy:true}])},[_c('v-row',[_c('v-col',[(_vm.tipoAutenticacao == 'database')?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-novo-usuario","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push("/administracao/usuarios/edit")}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Novo Usuário ")],1):_vm._e()],1)],1),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"100%"},attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loadingTable,"hide-default-header":_vm.loadingTable,"hide-default-footer":_vm.loadingTable,"sort-by":[],"sort-desc":true,"no-data-text":"Nenhum usuário encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.id))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate-column"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate-column"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.email)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.email))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status',{attrs:{"status":item.ativo}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"gray","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push(("/administracao/usuarios/edit/" + (item.id)))}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(_vm.canChangeStatus(item))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more-usuario","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openDialogChangeStatus(item)}}},[_c('v-list-item-title',[(item.ativo)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-account-cancel ")]):_c('v-icon',[_vm._v(" mdi-account ")]),(item.ativo)?_c('span',[_vm._v(" Inativar ")]):_c('span',[_vm._v(" Ativar ")])],1)],1)],1)],1):_vm._e()]}}],null,true)}),_c('dialog-change-status',{attrs:{"dialog":_vm.dialogChangeStatus},on:{"update:dialog":function($event){_vm.dialogChangeStatus = $event},"submitTriggered":function($event){return _vm.changeUserStatus(_vm.dialogChangeStatusData.id)}}},[_c('template',{slot:"title"},[(_vm.dialogChangeStatusData.ativo)?_c('span',[_vm._v(" Inativar ")]):_c('span',[_vm._v(" Ativar ")]),_vm._v(" usuário #"+_vm._s(_vm.dialogChangeStatusData.id)+" ")]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja "),(_vm.dialogChangeStatusData.ativo)?_c('span',[_vm._v(" inativar ")]):_c('span',[_vm._v(" ativar ")]),_vm._v(" o usuário "),_c('strong',[_vm._v(_vm._s(_vm.dialogChangeStatusData.name))]),_vm._v("? ")])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }